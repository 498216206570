.info {
    font-size: 11px;
}

.offre {
    color: #000;
    padding: 4%;
}

.box {
    width: 100%;
}

.div-details {
    height: 75%;
    background-color: rgb(243, 248, 247);
    overflow-y: scroll;
    border-radius: 2%;
}

.description {
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 600;
    color: #414141;
}

a {
    text-decoration: none;
    color: black;
}

.datepush {
    font-size: 12px;
    color: #37473f;
    font-weight: 500;
}

.target {
    color: rgb(6, 92, 32);
    font-weight: 500;
    font-size: 13px;
}

@media only screen and (max-width: 768px) { 

    .box {
        padding: 6%;
        width: 100%;
    }
    .description {
        font-size: 13px;
    }
    
    .datepush {
        font-size: 12px;
    }
    
    .target {
        font-size: 12px;
    }

    .offre {
        padding-top: 17%;
    }

}


