.App {
    margin-top: 3%;
    text-align: center;
  }

  .App-header {
    background: #02a049;
    /*background: linear-gradient(90deg,  rgb(25, 138, 42) 0%, rgb(40, 147, 56) 0%, rgb(29, 130, 44) 7.5% );
     background: linear-gradient(90deg, rgb(65, 176, 87) 0%, rgb(25, 138, 42) 100%, rgba(0,212,255,1) 100%); */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }

  .apps-image {
    width: 100%;
    height: 100%;
  }

  .developers-image {
    width: 100%;
    height: 100%;
  }

  .bg-home {
    background: #e5efe8;
    background: linear-gradient(90deg, rgb(81, 109, 96) 0%, rgb(25, 138, 42) 100%, rgba(0,212,255,1) 100%);
    height: 100vh;
  }

  .bg-title {
    /* background: #e5efe8;
    background: linear-gradient(90deg,  rgb(25, 138, 42) 0%, rgb(40, 147, 56) 0%, rgb(29, 130, 44) 0%, rgb(248, 253, 251) 95%);
    */
    /*background: #028e43;*/
	  background: #028e43;
    border-radius: 9px;
	  padding-bottom: 3.5%; padding-top: 2%;
  }
  
  .logo {
    /* background: left / cover url('https://fscl01.fonpit.de/userfiles/7687254/image/NextPit_Apps.jpg'); */
    background: rgb(2, 119, 39);
    background: linear-gradient(90deg, #00ff8c 0%, rgb(55, 167, 72) 35% 100%);
    display: flex;
    height: 180px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font:
      bold calc(1em + 2 * (100vw - 120px) / 100) 'Dancing Script',
      fantasy;
    color: #00ff8c;
    text-shadow: #000 2px 2px 0.2rem;
  }

  .formules-box {
    color: #000;
    padding: 1%;
    /* background-color: #c7e9c7;
    background: linear-gradient(90deg, rgba(9,121,26,1) 35%, rgb(81, 109, 96) 100%);  */
    background: #ecffef;
    /*background: linear-gradient(90deg, rgb(25, 138, 42) 0%, rgb(81, 109, 96) 100%,  rgba(0,212,255,1) 100%); 
    /*background: linear-gradient(90deg, rgb(81, 109, 96) 0%, rgb(25, 138, 42) 100%, rgba(0,212,255,1) 100%); */
  }

  .fleetbo {
    color: #05b365;
  }

  .beta {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #e9f3ee;
    font-size: 12px;
    
  }
  
  h1 {
    /*color: #05b365;*/
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: left;
    font-size: 60px;
    font-weight: 900;
  }

  .Brand-FleetBo {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #34c671;
    font-size: 60px;
    font-weight: 900;
  }

  .io {
    color: #cee7ce;
  }

  .Brand-little {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: left;
    font-size: 47px;
    font-weight: 900;
  }

  .title1 { font-size: 17px; color: #d0ead9; } 

  h5 {
    font-size: 25px;
    font-weight: bolder;
    text-align: left;
    color: #75f393;
    font-family:Arial, Helvetica, sans-serif
  }
  
  .brand {
    /* text-decoration: line-through; */
    text-align: left;
    font-size: 17px;
    font-weight: 700;
  }
  
  p {
    font-weight: 600;
    color: #c5e6d7 ;
    text-align: left;
  }
  
   .title2 { font-size: 19px; }
  
  .bg-media {
    /*background: left / cover url('https://i.pinimg.com/originals/fa/7f/e0/fa7fe0e1b123aee12d0a7f5c3142fb41.jpg'); */
    padding: 2%;
    border-radius: 2%;
    height: 100%;
    margin-top: 3%;
    background-color: transparent;
  }

  iframe, video{
    padding: 0%;
    background-color: transparent;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    margin-top: 2.5%;
    border: 0px solid #414141; 
  }
  
    .App-logo {
		height: 15vmin;
		pointer-events: none;
    }

    .text-desc {
		color: #d1ded2;
		font-size: 17px;
		font-weight: 550;
		font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    }

    .sub-title {
      font-weight: 900;
      color: #676666;
      font-size: 27px;
    }
  
    .footer, h6 {
        background-color: #eff5f1;
        font-weight: 550;
        font-size: 12px;
    }
  
    .bg-box-title {
	    margin-top: 5%;
	    margin-left: 1%;
    }
  
    .bg-add-project {
		margin: 1%;
		width: 280px;
		height: 220px;
		border-radius: 15px;
		background: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: calc(10px + 2vmin);
		color: white; 
    }
  
    .bg-box-project {
		margin: 1%;
		width: 280px;
		height: 220px;
		border-radius: 15px;
		background: #fff;
		font-size: calc(10px + 2vmin);
		color: white; 
    }

    .box-features {
		overflow: hidden;
		background: #fff;
		box-shadow: 2px 0 20px 0 rgba(79, 96, 143, 0.12);
		transition: all 0.3s ease-in-out;
		border-radius: 15px;
		z-index: 1;
		text-align: center;
		padding-bottom: 0%;
    }

    .box-features-mobile {
		width: 60%;
		overflow: hidden;
		background: #fff;
		box-shadow: 2px 0 20px 0 rgba(79, 96, 143, 0.12);
		transition: all 0.3s ease-in-out;
		border-radius: 15px;
		z-index: 1;
		text-align: center;
		padding-bottom: 0%;
    }
  
    .box-features-img {
		overflow: hidden;
		background: #fff;
		box-shadow: 2px 0 20px 0 rgba(79, 96, 143, 0.12);
		transition: all 0.3s ease-in-out;
		border-radius: 15px;
		z-index: 1;
		text-align: center;
		padding-bottom: 0%;
    }

    .col-sm-3 {
		width: 300px;
		padding: 1%;
    }

    .bf-launch {
        border: 2px solid #129e5f;
    }

    .div-details-welcome {
        text-align: left;
        height: 40%;
        margin-bottom: 5%;
        color:rgb(2, 119, 39)
    }

    .box-details-desc {
        text-align: left;
        line-height: 15px;
        height: 40px;
    }

    .details {
		font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
		font-size: 17px;
		font-weight: 500;
		color: #545454;
    }

    .detail-fleetbo {
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      font-size: 21px;
      font-weight: bolder;
    }

    .details-desc {
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      font-size: 21px;
      font-weight: 510;
      color: #375137;
    }

    .detail-fleetbo-desc {
		font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
		font-size: 19px;
		font-weight: 500;
		color: #4f4f4f;
		line-height: 27px;
    }

    .box-details-prices {
		float: left;
		text-align: left;
		margin-top: 10%;
		width: 100%;
    }

    .btn-start {
        position: relative;
        width: 100%;
        text-decoration: none;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-weight: 600;
        font-size: 17px;
        text-transform: none;
        letter-spacing: 1px;
        display: inline-block;
        padding: 15px 40px;
        border-radius: 25px;
        transition: 0.3s;
        line-height: 1;
        color: #fff;
        background: #099e51;
		    border: 1px solid #099e51;
        animation-delay: 0.3s;
        cursor: pointer;
        overflow: hidden;
    }

    .logos {
        width: 75px; height: 75px;
    }

  .offre {
    color: #05b365;
  }

  .tarif {
    font-size: 19px;
  }

  .tester {
    text-decoration: none;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    font-weight: 600;
    font-size: 17px;
    text-transform: none;
    letter-spacing: 1px;
    display: inline-block;
    padding: 15px 40px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #fff;
    background: #099e51;
    border: 2px solid #099e51;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    text-decoration: none;
    cursor: pointer;
 }


  .console {
    text-decoration: none;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    font-weight: 800;
    font-size: 16px;
    text-transform: none;
    letter-spacing: 1px;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 25px;
    transition: 0.3s;
    line-height: 1;
    color: #fff;
    background: #099e51; 
    border: 2px solid #fff;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    text-decoration: none;
    cursor: pointer;
  }
  
  .partners{
    font-size: 11px;
    font-weight: 600;
  }

  /* Pour les téléphones portrait (écran plus étroit) */
@media (max-width: 600px) {
  /* Styles pour les petits écrans (téléphones) */
  .Brand-FleetBo {
    font-size: 30px;
  }
  .Brand-little{
    font-size: 26px;
  }

  .bg-title {
    background: transparent;
  }

  .title1{
    font-size: 13px;
  }

  .detail-fleetbo{
    font-size: 17px;
  }

  .details-desc{
    font-size: 17px;
  }

  .detail-fleetbo-desc{
    font-size: 17px;
  }

  .why-fleetbo {
    padding: 5%; 
  }

  .box-devFleet-mobile {
    padding: 5%; 
  } 

  .div-formules{
    padding: 5%; 
  }

  .box-features-img{
    margin-top: 5%;
  }

  .box-features-mobile {
    width: 100%;
  }

}
  
  